//import waypoints from 'waypoints';
var waypoints = require("./jquery.waypoints.min.js");

class bhrs_waypoints_animation {
  static init() {
    // if ($("body").hasClass("homepage")) {
    var $bhrs_whatwedo_blocks_l = $(".bhrs-whatwedo-blocks-l");
    var $bhrs_whatwedo_blocks_r = $(".bhrs-whatwedo-blocks-r");
    var $bhrs_brand_blocks1 = $(".bhrs-brand-blocks").eq(0);
    var $bhrs_brand_blocks2 = $(".bhrs-brand-blocks").eq(1);
    var $bhrs_brand_blocks3 = $(".bhrs-brand-blocks").eq(2);
    var $bhrs_contact_blocks1 = $(".bhrs-contact-blocks").eq(0);
    var $bhrs_contact_blocks2 = $(".bhrs-contact-blocks").eq(1);
    var $bhrs_partners_container1 = $(".bhrs-partners-container").eq(0);
    var $bhrs_partners_container2 = $(".bhrs-partners-container").eq(1);
    var $normal1 = $(".normal").eq(0);
    var $reverse1 = $(".reverse").eq(0);
    var $normal2 = $(".normal").eq(1);
    var $reverse2 = $(".reverse").eq(1);
    var offSet = "75%";

    var waypoints = $normal1.waypoint({
      handler: function(direction) {
        $normal1.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $reverse2.waypoint({
      handler: function(direction) {
        $reverse2.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $normal2.waypoint({
      handler: function(direction) {
        $normal2.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $reverse1.waypoint({
      handler: function(direction) {
        $reverse1.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_whatwedo_blocks_l.waypoint({
      handler: function(direction) {
        $bhrs_whatwedo_blocks_l.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_whatwedo_blocks_l.waypoint({
      handler: function(direction) {
        $bhrs_whatwedo_blocks_l.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_whatwedo_blocks_r.waypoint({
      handler: function(direction) {
        $bhrs_whatwedo_blocks_r.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_brand_blocks1.waypoint({
      handler: function(direction) {
        $bhrs_brand_blocks1.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_brand_blocks2.waypoint({
      handler: function(direction) {
        $bhrs_brand_blocks2.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_brand_blocks3.waypoint({
      handler: function(direction) {
        $bhrs_brand_blocks3.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_contact_blocks1.waypoint({
      handler: function(direction) {
        $bhrs_contact_blocks1.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_contact_blocks2.waypoint({
      handler: function(direction) {
        $bhrs_contact_blocks2.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_partners_container1.waypoint({
      handler: function(direction) {
        $bhrs_partners_container1.addClass("active");
      },
      offset: offSet
    });

    var waypoints = $bhrs_partners_container2.waypoint({
      handler: function(direction) {
        $bhrs_partners_container2.addClass("active");
      },
      offset: offSet
    });
    //  } else {
    //  }
  }
}

module.exports = bhrs_waypoints_animation;
