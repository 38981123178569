"use strict";

$(function() {
  //Section Animation
  var bhrs_waypoints_animation = require("./modules/waypointsanimation.js");
  bhrs_waypoints_animation.init();

  var forEach = function(t, o, r) {
    if ("[object Object]" === Object.prototype.toString.call(t))
      for (var c in t)
        Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
    else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
  };

  var hamburgers = document.querySelectorAll(".hamburger");
  if (hamburgers.length > 0) {
    forEach(hamburgers, function(hamburger) {
      hamburger.addEventListener(
        "click",
        function() {
          this.classList.toggle("is-active");

          $(".bhrs-nav-sidedoor").toggleClass("active");
        },
        false
      );
    });
  }

  $(".bhrs-hero-image-downArrow").click(function() {
    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top - 50
      },
      500
    );
    return false;
  });

  if ($("body").hasClass("company")) {
    $("a.bhrs-nav-brandsBtn.company").addClass("active");
    $("a.bhrs-nav-brandsBtn.brands").removeClass("active");
    $("a.bhrs-nav-contactBtn.contact").removeClass("active");
  } else if ($("body").hasClass("brand")) {
    $("a.bhrs-nav-brandsBtn.brands").addClass("active");
    $("a.bhrs-nav-brandsBtn.company").removeClass("active");
    $("a.bhrs-nav-contactBtn.contact").removeClass("active");
  } else if ($("body").hasClass("homepage")) {
    $("a.bhrs-nav-brandsBtn.company").removeClass("active");
    $("a.bhrs-nav-brandsBtn.brands").removeClass("active");
    $("a.bhrs-nav-brandsBtn.contact").removeClass("active");
  } else if ($("body").hasClass("contact")) {
    $("a.bhrs-nav-brandsBtn.company").removeClass("active");
    $("a.bhrs-nav-brandsBtn.brands").removeClass("active");
    $("a.bhrs-nav-contactBtn.contact").addClass("active");
  }
});
